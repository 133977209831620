/*
* Elements colors
*/
/*
* Button colors
*/
/*
* Header
*/
/*
* Pagina base
*/
/*
* Footer
*/
/*
* Carousel
*/
/*
* Panel
*/
/*
* Table
*/
/*
* Well
*/
/*
* Pagination
*/
.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1 {
  width: 8.33%;
  float: left;
}
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2 {
  width: 16.66%;
  float: left;
}
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3 {
  width: 25%;
  float: left;
}
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4 {
  width: 33.33%;
  float: left;
}
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5 {
  width: 41.66%;
  float: left;
}
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6 {
  width: 50%;
  float: left;
}
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7 {
  width: 58.33%;
  float: left;
}
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8 {
  width: 66.66%;
  float: left;
}
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9 {
  width: 75%;
  float: left;
}
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10 {
  width: 83.33%;
  float: left;
}
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11 {
  width: 91.66%;
  float: left;
}
.img-responsive,
.thumbnail > img,
.thumbnail a > img,
.carousel-inner > .item > img,
.carousel-inner > .item > a > img {
  display: block;
  max-width: inherit;
}
div.container-fluid div.navbar-header .navbar-brand a .img-responsive {
  margin: 5px auto auto -15px;
}
header {
  background-color: #fff !important;
}
.container-fluid {
  clear: both;
}
.navbar-header {
  float: left;
}
.custom-ie-menu-bar {
  border-bottom: 1px solid #003366;
  float: left;
  clear: both;
  width: 100%;
  margin-top: 10px;
}
ul.navbar-main {
  padding-left: 15px;
  padding-right: 15px;
  float: left;
  vertical-align: bottom;
}
ul.navbar-main.navbar-nav {
  margin: 0;
}
ul.navbar-main li {
  float: left;
  margin-right: 1px;
}
ul.navbar-main li a {
  border: 1px solid #003366;
  border-bottom: none;
  background-color: #003366;
  color: #fff !important;
  padding: 3px 5px;
}
ul.navbar-main li a:hover {
  background-color: #336699;
  color: #fff !important;
}
ul.navbar-main li a:active {
  background-color: #336699;
  color: #fff !important;
}
form.navbar-right {
  display: none;
  visibility: hidden;
  float: right;
  border: none;
  width: 25%;
  height: 14px;
  margin: -8px 0;
  padding: 0;
}
form.navbar-right.visible-lg {
  display: block !important;
}
form.navbar-right div.form-group div.input-group input.form-control {
  height: 32px;
  z-index: 2;
  float: left;
  margin-bottom: 0;
  border-bottom-right-radius: inherit;
  border-top-right-radius: inherit;
  display: block;
  padding: 0;
  color: #555555;
  background-color: #fff;
  box-sizing: content-box;
  font-size: 18px;
  line-height: 32px;
}
form.navbar-right div.form-group div.input-group .input-group-btn button.btn {
  color: #eee;
  background-color: #003366;
}
.table-blu thead th {
  background-color: #003366;
  color: #fff;
  text-align: center;
}
.table-blu tbody th {
  background-color: #003366;
  color: #fff;
  vertical-align: middle !important;
}
.container-main h1.page-title {
  border-bottom: 1px solid #003366;
  font-weight: 300;
  margin-bottom: 30px;
  margin-top: 0;
  padding-bottom: 5px;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.text-justify {
  text-align: justify;
}
table.table.table-striped {
  border: 1px solid #ccc;
}
table.table.table-striped .visible-lg-block {
  display: block !important;
}
div.container-main section.col-sm-9 {
  float: right;
}
div.container-main div.col-sm-6 {
  display: inline-block;
  float: none;
}
div.container-main div.col-sm-6 .row .col-md-12 {
  margin-top: 15px;
  margin-bottom: 15px;
}
div.container-main .sidebar-right {
  display: inline-block;
  float: right;
}
div.container-main .sidebar-left {
  display: inline-block;
  float: left;
}
footer {
  background-color: #F3F0F1;
  border-top: 1px solid #003366;
  padding-bottom: 30px;
  padding-top: 30px;
  margin-top: 30px;
}
footer .container-fluid .row.visible-xs {
  display: none;
  visibility: hidden;
}
footer .container-fluid .row.hidden-xs {
  vertical-align: middle !important;
  text-align: center;
}
footer .container-fluid .row.hidden-xs .col-md-4 {
  position: inherit;
  float: left;
  vertical-align: middle !important;
  text-align: center;
}
footer .container-fluid .row nav.navbar {
  background-color: #003366;
  clear: both;
  border: none;
  min-height: inherit;
  color: #fff !important;
  margin-top: 45px;
  padding: 0;
}
footer .container-fluid .row nav.navbar .footer-menu ul.navbar-nav li a {
  color: #fff;
  padding: 10px;
}
footer .container-fluid .row nav.navbar .footer-menu ul.navbar-nav li a:hover {
  background-color: #004080;
}
.breadcrumb > li + li:before {
  content: "\>>";
}
.custom-blu-btn-link {
  background-color: #003366;
  color: #fff;
  border: 1px solid #003366;
}
