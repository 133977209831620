@import 'dashboard-colors.less';
@import 'app-colors.less';

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1 {
	width: 8.33%;
	float: left;
}

.col-xs-2, .col-sm-2, .col-md-2, .col-lg-2 {
	width: 16.66%;
	float: left;
}

.col-xs-3, .col-sm-3, .col-md-3, .col-lg-3 {
	width: 25%;
	float: left;
}

.col-xs-4, .col-sm-4, .col-md-4, .col-lg-4 {
	width: 33.33%;
	float: left;
}

.col-xs-5, .col-sm-5, .col-md-5, .col-lg-5 {
	width: 41.66%;
	float: left;
}

.col-xs-6, .col-sm-6, .col-md-6, .col-lg-6 {
	width: 50%;
	float: left;
}

.col-xs-7, .col-sm-7, .col-md-7, .col-lg-7 {
	width: 58.33%;
	float: left;
}

.col-xs-8, .col-sm-8, .col-md-8, .col-lg-8 {
	width: 66.66%;
	float: left;
}

.col-xs-9, .col-sm-9, .col-md-9, .col-lg-9 {
	width: 75%;
	float: left;
}

.col-xs-10, .col-sm-10, .col-md-10, .col-lg-10 {
	width: 83.33%;
	float: left;
}

.col-xs-11, .col-sm-11, .col-md-11, .col-lg-11 {
	width: 91.66%;
	float: left;
}

.col-xs-, .col-sm-, .col-md-, .col-lg- {

}

.img-responsive, .thumbnail > img, .thumbnail a > img, .carousel-inner > .item > img, .carousel-inner > .item > a > img {
	display: block;
	max-width: inherit;
}

div.container-fluid {
	div.navbar-header {
		.navbar-brand {
			a {
				.img-responsive {
					margin: 5px auto auto -15px;
				}
			}
		}
	}
}

header {
	background-color: @header-bg !important;
}

.container-fluid{
	clear: both;
}

.navbar-header {
	float: left;
}

.custom-ie-menu-bar {
	border-bottom: 1px solid @header-border;
	float: left;
	clear: both;
	width: 100%;
	margin-top: 10px;
}

ul.navbar-main {
	padding-left: 15px;
	padding-right: 15px;
	float: left;
	vertical-align: bottom;
	&.navbar-nav {
		margin: 0;
	}
	li {
		float: left;
		margin-right: 1px;
		a {
			border: 1px solid @header-navbar-element-border;
			border-bottom: none;
			background-color: @header-navbar-element-bg;
			color: @header-navbar-element-color !important;
			padding: 3px 5px;
		}
		a:hover {
			background-color: @header-navbar-element-hover-bg;
			color: @header-navbar-element-hover-color !important;
		}
		a:active {
			background-color: @header-navbar-element-hover-bg;
			color: @header-navbar-element-hover-color !important;
		}
	}
}

form.navbar-right {
	display: none;
	visibility: hidden;
	float: right;
	border: none;
	width: 25%;
	height: 14px;
	margin: -8px 0;
	padding: 0;
	&.visible-lg {
		display: block !important;
	}
	div.form-group {
		div.input-group {
			input.form-control {
				height: 32px;
				z-index: 2;
				float: left;
				margin-bottom: 0;
				border-bottom-right-radius: inherit;
				border-top-right-radius: inherit;
				display: block;
				padding: 0;
				color: #555555;
				background-color: #fff;
				box-sizing: content-box;
				font-size: 18px;
				line-height: 32px;
			}
			.input-group-btn {
				button.btn {
					color: @header-navbar-form-btn-color;
					background-color: @header-navbar-form-btn-bg;
				}
			}
		}
	}
}

.table-blu {
	thead {
		th {
			background-color: @table-blu-head-bg;
			color: @table-blu-head-color;
			text-align: center;
		}
	}
	tbody {
		th {
			background-color: @table-blu-head-bg;
			color: @table-blu-head-color;
			vertical-align: middle !important;
		}
	}
}

.container-main {
	h1.page-title {
		border-bottom: 1px solid #003366;
		font-weight: 300;
		margin-bottom: 30px;
		margin-top: 0;
		padding-bottom: 5px;
	}
}

.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}

.text-left {
	text-align: left;
}

.text-justify {
	text-align: justify;
}

table.table {
	&.table-striped {
		border: 1px solid #ccc;
		.visible-lg-block {
			display: block !important;
		}
	}
}

div.container-main {
	section.col-sm-9 {
		float: right;
	}
	div.col-sm-6 {
		display: inline-block;
		float: none;
		.row {
			.col-md-12 {
				margin-top: 15px;
				margin-bottom: 15px;
			}
		}
	}
	.sidebar-right {
		display: inline-block;
		float: right;
	}
	.sidebar-left {
		display: inline-block;
		float: left;
	}
}

footer {
	background-color: @footer-bg;
	border-top: 1px solid @footer-border-color;
	padding-bottom: 30px;
	padding-top: 30px;
	margin-top: 30px;
	.container-fluid {
		.row {
			&.visible-xs {
				display: none;
				visibility: hidden;
			}
			&.hidden-xs {
				vertical-align: middle !important;
				text-align: center;
				.col-md-4 {
					position: inherit;
					float: left;
					vertical-align: middle !important;
					text-align: center;
				}
			}
			nav.navbar {
				background-color: @footer-navbar-bg;
				clear: both;
				border: none;
				min-height: inherit;
				color: @footer-navbar-color !important;
				margin-top: 45px;
				padding: 0;
				.footer-menu {
					ul.navbar-nav {
						li {
							a {
								color: @footer-navbar-color;
								padding: 10px;
							}
							a:hover {
								background-color: @footer-navbar-element-hover-bg;
							}
						}
					}
				}
			}
		}
	}
}

.breadcrumb > li + li:before {
	content: "\>>";
}

.custom-blu-btn-link {
	background-color: @button-primary-bg;
	color: @button-primary-color;
	border: 1px solid @button-primary-border;
}